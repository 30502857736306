// Import Swiper and modules
import Swiper, { Navigation } from 'swiper';

/**
 * Swipe Slider
 */
const KGSliderTitres = () => {

    const $sliderTitres = document.getElementById('kg-slider-titres');
    if (!$sliderTitres) {
        return false;
    }

    const swiperTitres = new Swiper($sliderTitres, {
        modules: [Navigation],
        slideClass: 'kg-slider__slide',
        wrapperClass: 'kg-slider__wrapper',
        grabCursor: true,
        slidesPerView: 2,
        spaceBetween: 12,
        breakpoints: {
            460: {
                slidesPerView: 2,
                slidesPerGroup: 2
            },
            640: {
                slidesPerView: 3,
                slidesPerGroup: 3
            },
            960: {
                slidesPerView: 2,
                slidesPerGroup: 2
            },
            1240: {
                slidesPerView: 3,
                slidesPerGroup: 3
            }
        }
    });

    if (document.querySelector('.kg-slider__navigation--titres')) {
        const button = {
            prev: document.querySelector('.kg-slider__navigation--titres-button--prev'),
            next: document.querySelector('.kg-slider__navigation--titres-button--next')
        };

        // Click Events
        if ( button.prev && button.next ) {
            button.prev.addEventListener('click', (e) => swiperTitres.slidePrev());
            button.next.addEventListener('click', (e) => swiperTitres.slideNext());
        }
    }
};

document.addEventListener('DOMContentLoaded', KGSliderTitres);